<template>
  <div id="newsStoryContent">
    <p id="Text1">
      This weekend, the 2011 Nexus Force Championships kick off with the first Build Event!
      Minifigures will also compete in mini-game events starting next weekend. Participating
      in each event will earn Nexus Force Championship Points which will determine the 2011
      Nexus Force Champion!
      <br><br>
      Some Championship events are located in Members Only zones, such as the Cannon Cove
      Shooting Gallery in Gnarled Forest or the Dragonmaw Chasm Racetrack in Forbidden Valley.
      While Free-to-Play Minifigures can participate in the Avant Gardens Survival contest and
      the Build Events, your chances to be crowned the true Champion will skyrocket by becoming
      a Member of LEGO Universe to unlock all of the Championship event zones!
      <br><br>
      <!-- pic566D1844B3A44761CA09EB3A1D3D4A2D.jpg -->
      <img style="width: 440px; height: 261px;" class="rounded" src="@/assets/news-network/nexus-force-championships-2011.jpg">
      <br><br>
      <b>How to win!</b>
      <br><br>
      Each event will be held similar to our contests in the past. You will participate in
      a mini-game, a racing event, or building event. However, along with cool prizes, you
      will also win Nexus Force Championship points!
      <br><br>
      These points will be added up over the Championship period. In mini-game and racing events,
      the first place winner in each age group will get 4 Nexus Force Championship points. 2nd
      through 4th will get 3 Nexus Force Championship points and 5th through 10th will get 2
      points. Everyone else gets 1 point.
      <br><br>
      This means that even if you are in last place, you still have a good chance, as you can
      get more points the following week!
      <br><br>
      As build events take three weeks (plus one week for judging), they offer slightly more
      points as a reward.
      <br><br>
      The Nexus Force Champion should be the best overall player, and this scoring system will
      allow the players who are best in all aspects of LEGO Universe to rise to the top.
      <br><br>
      <b>Event Schedule</b>
      <br><br>
      The Nexus Force Championships will run for the next 8 weeks.<br>
    </p>

    <b>October Schedule</b>
    <table border="1">
      <tbody>
        <tr class="odd">
          <td align="CENTER"><b>Start Date</b></td>
          <td align="CENTER"><b>End Date</b></td>
          <td align="CENTER"><b>Event</b></td>
          <td align="CENTER"><b>Eligibility</b></td>
        </tr>
        <tr class="even">
          <td align="CENTER">October 14</td>
          <td align="CENTER">November 5</td>
          <td align="CENTER">Build Event 1</td>
          <td align="CENTER">Free-To-Play/Members</td>
        </tr>
        <tr class="odd">
          <td align="CENTER">October 21</td>
          <td align="CENTER">October 23</td>
          <td align="CENTER">Avant Gardens Survival</td>
          <td align="CENTER">Free-To-Play/Members</td>
        </tr>
        <tr class="even">
          <td align="CENTER">October 28</td>
          <td align="CENTER">October 30</td>
          <td align="CENTER">Battle of Nimbus Station</td>
          <td align="CENTER">Members Only</td>
        </tr>
      </tbody>
    </table>

    <b>November Schedule</b>
    <table border="1">
      <tbody>
        <tr class="odd">
          <td align="CENTER"><b>Start Date</b></td>
          <td align="CENTER"><b>End Date</b></td>
          <td align="CENTER"><b>Event</b></td>
          <td align="CENTER"><b>Eligibility</b></td>
        </tr>
        <tr class="even">
          <td align="CENTER">November 4</td>
          <td align="CENTER">November 6</td>
          <td align="CENTER">Cannon Cove Shooting Gallery</td>
          <td align="CENTER">Members Only</td>
        </tr>
        <tr class="odd">
          <td align="CENTER">November 11</td>
          <td align="CENTER">December 2</td>
          <td align="CENTER">Build Event 2</td>
          <td align="CENTER">Free-To-Play/Members</td>
        </tr>
        <tr class="even">
          <td align="CENTER">November 18</td>
          <td align="CENTER">November 20</td>
          <td align="CENTER">Vertigo Loop Racetrack</td>
          <td align="CENTER">Members Only</td>
        </tr>
        <tr class="odd">
          <td align="CENTER">November 25</td>
          <td align="CENTER">November 27</td>
          <td align="CENTER">Keelhaul Canyon Racetrack</td>
          <td align="CENTER">Members Only</td>
        </tr>
      </tbody>
    </table>

    <b>December Schedule</b>
    <table border="1">
      <tbody>
        <tr class="odd">
          <td align="CENTER"><b>Start Date</b></td>
          <td align="CENTER"><b>End Date</b></td>
          <td align="CENTER"><b>Event</b></td>
          <td align="CENTER"><b>Eligibility</b></td>
        </tr>
        <tr class="even">
          <td align="CENTER">December 2</td>
          <td align="CENTER">December 4</td>
          <td align="CENTER">Dragonmaw Chasm Racetrack</td>
          <td align="CENTER">Members Only</td>
        </tr>
      </tbody>
    </table>

    <p>
      <b>Winners!</b>
      <br><br>
      On top of the normal weekly winners, we will have 13 Champions:
      <br><br>
      <b>Nexus Force Champion</b> – 1 Player – the player with the most points among all players on all servers.
      <br><br>
      <b>Overbuild/Storm/Exeter Overall Champion</b> – 3 Players – the player with the most points overall for his/her server.
      <br><br>
      <b>Overbuild/Storm/Exeter Battle Champion</b> – 3 Players – the player with the most points in the Battle mini-games for his/her server.
      <br><br>
      <b>Overbuild/Storm/Exeter Race Champion</b> – 3 Players – the player with the most points in the Racing mini-games for his/her server.
      <br><br>
      <b>Overbuild/Storm/Exeter Build Champion</b> – 3 Players – the player with the most points in the building events for his/her server.
      <br><br>
      The players who are the Nexus Force Champion or the Overall Server Champion cannot also be the Battle/Race/Build Champion.
      <br><br>
      Head over to the
      <router-link to="/messageboards/4386709">message boards</router-link>
      to discuss the 2011 Nexus Force Championships!
    </p>
  </div>
</template>
